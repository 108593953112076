//
// Providers.scss
//

// observation counter
.observation-counter {
   position: absolute;
   transform: translate(10px, -20px);
   right: 35px;

   span {
      color: #aaa;
   }
}
