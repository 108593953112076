//
// Providers.scss
//

// Size of the icons
.display-grid {
    display: grid;
}

.col-icon {
    align-items: center;
    font-size: xx-large;
}
